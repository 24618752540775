/* NotificationMenu.css */
.bell-toggle {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    direction: ltr;
  }
  
  .bell-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .notification-counter {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 50%;
    overflow-wrap: break-word; 
    white-space: normal; 
  
  }
  
  .notification-item {
    display: flex;
    align-items: center;
    direction: ltr;
    word-break: break-word;
    white-space: normal;

  }
  
  .notification-image {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    object-fit: cover;
    border-radius: 50%;
    margin-left: 15px;
  }
  
  .notification-content {
    flex: 1;
    width: 300px;
    text-align: right;

  }
  
  .notification-content p {
    margin-bottom: 5px;
  }
  
  .notification-content small {
    color: #999;
  }
  
  /* Scrollable dropdown menu */
.dropdown-menu {
    max-height: 800px; /* Set the maximum height you want */
    overflow-y: auto;
  }
  