.ChartComponentMaster{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.ChartComponentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 200px;
    background-color: var(--black-white);
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
    border : 2px solid #0000003a;


  }
  
  .legend {
    display: flex;
    flex-direction: column;
    margin-right: 20px; 
  }
  
  .legendItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px; 
    font: 600 16px Cairo;

  }
  
  .legendColor {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin: 0 10px 0 10px;
  }
  
  .legendValue {
    margin: auto;
    padding: 0 50px 0 50px;
    font: 700 16px Cairo;
  }
  .ChartComponentTitle{
    
    font: 700 18px Cairo;
    color: var(--input-color);
    margin-right:60px ;
    }
  .ChartComponentSummation{
font: 700 20px Cairo;
position: relative;
    left: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

.canvas{
  display: flex;
  width: 30%;
  height: 165px;
}
