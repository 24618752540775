.tablediv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px;
    overflow: auto;

  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2;
  }

.table1 {
    width: 90%;
    border-collapse: collapse;
    margin: 20px;
    overflow: auto;


  }
  
  .table1 th,
  .table1 td {
    border: 1px solid #ddd;
    width: 200px;
    padding: 8px;
    text-align: center;
  }
  
  .table1 th {
    background-color: #f2f2f2;
  }
.table2 {
    width: 70%;
    border-collapse: collapse;
    margin: 20px;
    overflow: auto;


  }
  
  .table2 th,
  .table2 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table2 th {
    background-color: #f2f2f2;
  }
 
.table3 {
    width: 30%;
    border-collapse: collapse;
    margin: 20px;
    overflow: auto;


  }
  
  .table3 th,
  .table3 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table3 th {
    background-color: #f2f2f2;
  }
  .tabledateinput{
    border: none;
  }
  .backicon{
    align-self: start;
    margin-top: 20px;
    margin-right: 40px;
    font-family: Cairo;
    background-color: #0080FB;
    padding: 8px;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #0080FB;
  }
  .userName {
    display: flex;
    flex-direction: column;
    gap: 8px;
    direction: rtl;
  }

.UserNameLabel {
  height: 30px;
  font: 700 16px cairo;
  color: #333333;
  line-height: 29.98px;
  text-align: right;
}

.userNameSelect {
  display: flex;
  width: 201px;
  height: 38px;
  font: 700 16px cairo;
  color: #797981;
  line-height: 29.98px;
  border: 1px solid #0080fb;
  border-radius: 4px;
  padding: 0 16px;
}

.filteration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 38.59px;
  border-radius: 4px;
  border: 1px solid #0080FB;
  background: #0080FB;
  font: 700 16px cairo;
  line-height: 29.98px;
  color: #ffffff;
  cursor: pointer;
}
.cancelfilteration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 38.59px;
  border-radius: 4px;
  color: #0080FB;
  border: 1px solid #0080FB;
  font: 700 16px cairo;
  line-height: 29.98px;
  text-align: center;
  cursor: pointer;
}
 
  