.MasterPage2{
    display: flex;
    flex-direction: column;
    justify-content: start ;
    align-items: start;
    margin-top: 20px ;
    margin-right: 10px;
}
.ChartsPageMaster{
    padding: 17.5px;
    background: #EBEFEF;
}