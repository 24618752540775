html {
  font-family: "Cairo";
  background-color: var(--background-color);

}

:root {
  --main-color: #0080fb;
  --background-color: #ebebeb;
  --background-sidebar: #f7f7f7;
  --background-color2: #d9d9d9;
  --background-white: white;
  --background-white: white;
  --sideBarActive-color: white;
  --background-white2: white;
  --background-white3: white;
  --black-white: white;
  --background-table-head: #f2f6ff;
  --header-color: #000;
  --input-color: #333333;
  --input-color: #000; /* Light grey for headers to contrast with dark blue */
  --border-color: #0080fb; /* Light grey for headers to contrast with dark blue */
  --stripe-color: rgba(242, 246, 255, 1);
  --input-bottom: rgba(242, 246, 255, 1);
  --font-color: #333 ;
  --text-color2: blue; /* Light grey text color for better contrast */

  ;
  
  
  
  
  
  
  direction: rtl;
  font-family: Cairo;
  font-size: 20px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: 0em;
}


/* :root.dark-mode {
  --main-color: #35383b;
  --background-color: #222;
  --background-color2: #333;
  --background-white: white;
  --background-table-head: #555;
  --text-color: #fff;
  --button-color: #333;
  --link-color: #0d6efd;
  --header-color: rgb(219, 219, 219);

} */
:root.dark-mode {
  /* --main-color: #1a1a1a; Dark mode color for main elements */
  --main-color: #000; /* Dark mode color for main elements */
  --background-color: #123; /* Dark blue for background */
  --background-color2: #1a1a1a; /* Slightly lighter background color */
  --background-sidebar: #000000aa;
  --background-white: #fff; /* Light greyish background for dark mode */
  --background-white2: #121212; /* Light greyish background for dark mode */
  /* --background-white2: #4e545c; Light greyish background for dark mode */
  --background-white3: ##8d9797; /* Light greyish background for dark mode */
  --background-white4: ##aeb1b4; /* Light greyish background for dark mode */
  --black-white: #000;
  --background-white5: #1c1c1c; /* Light greyish background for dark mode */
  --sideBarActive-color: #6f6f6f;
  --background-table-head: #1a1a1a; /* Darker color for table headers */
  --text-color: #fff; /* Light grey text color for better contrast */
  --text-color2: #dfdfdf; /* Light grey text color for better contrast */
  --button-color: #444; /* Darker grey for buttons */
  --link-color: #4a90e2; /* Bright blue for links to stand out */
  --header-color: #d0d0d0; /* Light grey for headers to contrast with dark blue */
  --border-color: #000; /* Light grey for headers to contrast with dark blue */
  --input-color: #fff; /* Light grey for headers to contrast with dark blue */
  --stripe-color: rgba(91, 93, 118, 0.695);
  --input-bottom: rgb(101 99 99 / 92%);;
  --font-color: #B0B0B0 ;


}
.main-BG-Color{
  background-color: var(--black-white);
}


/* LoadingDots.css */
.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem; /* Space between dots */
}

.dot {
  width: 10px; /* Size of the dot */
  height: 10px; /* Size of the dot */
  border-radius: 50%;
  background-color:#808080;
  /* Change color as needed */
  animation: bounce 0.6s infinite alternate;
}

@keyframes bounce {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-10px);
  }
}




.stripe tr:nth-child(even) {
  background-color: var(--stripe-color);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}
.borderBlack{
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
}
/* .sidebarMenu{
  background-color: var(--background-sidebar);
  transition: width 0.3s ease;

} */
.backgroundForDarkMode{
  background-color: var(--background-color);
}
.sacondBackground{
  background-color: var(--background-white2);
  border-radius: 4px;

}

/* input::value, */
.multiSelect,
select,
input{
  background-color: var(--background-white3);
  border: var(--border-color);
  color: var(--input-color);
}
.tableDarkBody{
  background-color: var(--background-aeb1b4);

}


td {
  font-family: cairo;
}

.whiteBackground {
  background-color: var(--background-white2);
}

.addUser {
  /* width: 1115px; */
  min-height: 1287px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 28px;
}

.firstContainer {
  /* overflow-x: auto; */
  width: 100%;
  /* display: contents; */
}

.firstContainer2 {
  overflow: auto;
  width: 100%;
  display: contents;
}
.betweenAndCenterResponsive {
  justify-content: space-between;
}

@media only screen and (max-width: 1350px) {
  .firstContainer {
    display: flex;
    flex-wrap: wrap;
  }
}

.tableComession {
  min-height: 371px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); */
  width: 100%;
}

.dashboard {
  background-color: var(--background-color);
  padding: 1rem;
  /* display: flex;
overflow: hidden; */
  /* height: max-content; */
}

/* Base styling */
.loginPage {
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Prevents any overflow */
}

.loginBox {
  background-color: white;
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  padding: 20px;
  box-sizing: border-box; /* Ensures padding and border are included in width/height */
  overflow: hidden; /* Prevents any child elements from overflowing */
}

.logoContainer {
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px; /* Ensures logo does not exceed container width */
  height: auto; /* Maintains aspect ratio */
  overflow: hidden; /* Prevents overflow if image is larger */
}

.logoContainer img {
  width: 100%; /* Makes sure the image fits within the container */
  height: auto;
  object-fit: contain;
  display: inline; /* Removes inline space below the image */
}

.userLoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.username {
  font-family: Cairo;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  text-align: right;
  width: 100%;
  margin-bottom: 15px;
  color: #000;
}

.username input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  border: 1px solid var(--main-color);
  box-sizing: border-box; /* Ensures padding and border are included in width/height */
}

input::placeholder {
  color: #d9d9d9;
  font-size: 18px;
}

.loginButton {
  color: white;
  border: none;
  background-color: var(--main-color);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-family: Cairo;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 20px;
  box-sizing: border-box; /* Ensures padding and border are included in width/height */
}

/* Media Queries for responsiveness */
@media (max-width: 999px) {

  .customModal .modal-dialog {
    max-width: 100% !important;
    width: 100% !important; /* Ensures the modal's width */
  
  }
  .customModal .modal-body {
    width: 100% !important; /* Ensures the body matches the modal's width */
    margin: 0 auto; /* Centers the content inside the modal */
  }
  .modal-content{
    width: 100% !important;
    margin: auto !important;
    
  }}
@media (max-width: 768px) {

 

  .loginBox {
    width: 90%;
    padding: 15px;
  }

  .logoContainer {
    max-width: 150px;
    max-height: 150px;
  }

  .username {
    font-size: 14px;
  }

  .username input {
    height: 35px;
    padding: 0 10px;
  }

  .loginButton {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .loginBox {
    width: 95%;
    padding: 10px;
  }

  .logoContainer {
    max-width: 120px;
    max-height: 120px;
  }

  .username {
    font-size: 14px;
  }

  .username input {
    height: 30px;
    padding: 0 8px;
  }

  .loginButton {
    font-size: 14px;
    padding: 8px;
  }
}


.navBar {
  background-color: var(--main-color);
  color: white;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
}

.dropdownitem {
  text-align: center;
}

.navbarImg {
  display: flex;
  gap: 17px;
  align-items: center;
}

.navbarrigth {
  display: flex;
  align-items: center;
}

.sidebarMenu {
  color: #797981;
  /* height: 100vh; */
  overflow-y: auto;
  width: 210px;
  display: flex;
  gap: 24px;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

/* .sidebarMenu {
  background-color: var(--background-sidebar);
  transition: width 0.3s ease;
} */

.sidebarOpen {
  width: 220px; /* Desired width when open */
}

.sidebarClosed {
  width: 60px; /* Desired width when closed */
}

.sidebarMenu {
  background-color: var(--background-sidebar);
  transition: width 0.6s ease-in-out;
  width: 40px; /* Adjust this width for the closed state */
}

.sidebarMenu.sideBarBurger {
  width: 210px; /* Adjust this width for the open state */
}

.link_text {
  display: inline; /* Ensure titles are inline */
}

.icon {
  width: 30px; /* Adjust icon width if necessary */
}

.inputField {
  width: 100%; /* Ensure the input takes full width */
  padding-right: 40px; /* Add padding to the right for the button */
}



.passwordInputContainer {
  position: relative; /* Position relative for absolute positioning of the button */
}

.togglePasswordButton {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 10px; /* Position from the right */
  transform: translateY(-50%); /* Adjust for vertical centering */
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  color: #000; /* Change to your preferred color */
  transition: 0.3s;

}
.togglePasswordButton:hover {
  background-color: gray; /* Change background to gray on hover */
  border-radius: 40%;
}


.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: right;
}

.link {
  display: flex;
  color: #797981;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
}

.link:hover {
  background-color: #8a8a8c93;
  color: white;
  transition: 0.5s;
}

.active {
  border-left: 4px solid var(--main-color);
  color: var(--main-color);
  background: var(--sideBarActive-color);
}

.activeiconGray {
  color: #797981;
  border-left: none;
}

.activeicon {
  color: var(--main-color);
}

.active-yellow {
  border-left: 4px solid rgb(254, 170, 0);
  color: rgb(254, 170, 0);
  background: white;
  /* color: rgb(254, 170, 0); */
}

.link_text {
  font-family: Cairo;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: right;
  width: fit-content;
  height: 30px;
}

.logoMenu {
  font-size: 18px;
  line-height: 0;
}

.addButton {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  text-align: center;
  color: white;
  background-color: var(--main-color);
  /* width: 20%; */
  height: 50px;
  /* padding: 10px 16px 10px 16px; */
  padding: 5px;
  gap: 10px;
  border-radius: 4px;
  border: none;
  margin-bottom: 5px;
}

.filter {
  display: flex;
  gap: 0;
  font-family: Cairo;
}

.dropdown-item {
  font-size: 14px;
}

.filtericon {
  color: #797981;
  background-color: var(--background-color2);
  border: none;
  font-weight: 600;
  height: 44px;
}

.activeFilter {
  color: white;
  background-color: var(--main-color);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pdfBox {
  /* width: 141.83px; */
  height: 44px;
  padding: 10px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #0080fb;
  background-color: white;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Cairo;
}

.usersSearchBox {
  /* width: 1107px; */
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  margin-top: 30px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.25);
  background-color: var(--background-white2);
}

.title {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: right;
  color: rgba(51, 51, 51, 1);
}
.medicalSystemLayout {
  width: 100%; /* Ensure full width */
  padding: 16px 0; /* Top and bottom padding */
  border-radius: 8px;
  background-color: var(--background-white2);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px; /* Set a max-width for the container */
  margin: 0 auto; /* Center the container */
  padding: 0 16px; /* Horizontal padding */
}

.customRow {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  justify-content: center; /* Space between items */
}

.customCol {
  flex: 1 1 calc(33.33% - 16px); /* Responsive width, allowing for margin */
  margin: 8px; /* Space around columns */
  max-width: calc(33.33% - 16px); /* Ensure columns do not exceed this width */
  box-sizing: border-box; /* Include padding in width */
}

.cityCard {
  display: flex;
  flex-direction: column; /* Stack image and text */
  align-items: center; /* Center content */
  height: auto; /* Allow height to adjust based on content */
  width: 100%; /* Ensure the card uses full width of its container */
  max-width: 100%; /* Prevent overflow */
}

.city-container {
  background-image: url("../src/Images/medical-system.png");
  background-size: cover;
  background-position: center; /* Center image */
  height: 350px; /* Fixed height for the image */
  border-radius: 8px; /* Rounded corners */
  width: 100%; /* Full width of parent */
}

.cityName {
  font-family: Cairo, sans-serif; /* Fallback font */
  font-size: 32px; /* Default font size */
  font-weight: 400;
  line-height: 1.5; /* Adjust line height */
  text-align: center; /* Center the text */
  width: 100%; /* Full width for mobile */
  margin-top: -60px; /* Adjust position */
  background: rgba(255, 255, 255, 0.9); /* Slight transparency */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 0; /* Padding for text */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .customCol {
    flex: 1 1 100%; /* Full width on mobile */
    max-width: 100%; /* Ensure no overflow */
    margin: 8px 0; /* Vertical spacing */
  }

  .city-container {
    height: 200px !important; /* Reduced height for mobile */
  }

  .cityName {
    font-size: 24px; /* Smaller font size for mobile */
    margin-top: -40px; /* Adjust for alignment */
    padding: 15px; /* Adjust padding */
  }
  .medicalSystemLayout {
    width: 100%; /* Ensure full width */
  }  
}

.borderPrint {
  display: flex;
  justify-content: center;
  /* width: 32px; */
  padding: 2px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  color: var(--header-color);
}
.title,
.printColor{
  color: var(--header-color);
}

.searchBar {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  /* gap: 40px; */
  /* width: 1032px; */
}

input,
select {
  border-radius: 4px;
  border: 1px solid rgba(0, 128, 251, 1);
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

select {
  width: 163px;
  height: 33px;
  color:  var(--font-color) ;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  -webkit-appearance: none; /* For Chrome, Safari */
  -moz-appearance: none;    /* For Firefox */
  appearance: none;         /* Standard */
  
}


select option:checked {
  color:  var(--font-color);
}

input:focus,
select:focus {
  outline: var(--main-color);
  color:  var(--font-color);
  border-radius: 8px;
  border: 1px solid rgba(0, 128, 251, 1);
}

input::placeholder {
  color:  var(--font-color);
  text-align: center;
  font-weight: 600;
  font-size: 16px !important;
}

.activeSearch {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  background-color: rgba(132, 203, 0, 1);
  color: white;
  border: none;
  padding-top: 2px;
  width: 127px;
  height: 46px;
}

.notActiveSearch {
  font-family: Cairo;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  background-color: rgba(240, 20, 47, 1);
  color: white;
  border: none;
  padding-top: 2px;
  width: 127px;
  height: 46px;
}

.label {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 29.98px;
  text-align: right;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.width45 {
  width: 45%;
}
.width100 {
  width: 100%;
}

.startSearch {
  color: white;
  background-color: rgba(254, 170, 0, 1);
  font-family: Cairo;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  width: 164px;
  height: 50px;
  margin-top: 4px;
  border-radius: 4px;
  margin-left: 10px;
}

.hideTitle {
  visibility: hidden;
}

.inputContainer {
  display: flex;
  /* width: 1051px; */
  gap: 8px;
  flex-wrap: wrap;
}

.inputContainerProfile {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.eyeButton {
  /* width: 10%; */
  border: none;
  border: 1px solid var(--main-color);
  background-color: var(--background-white2);
}

.inputContainer .header {
  display: flex;
  justify-content: space-between;
  background-color: var(--main-color);
  color: var(--background-white);
  /* width: 1086px; */
  height: 50px;
  align-items: center;
  /* padding: 32px 0px 0px 0px; */
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  padding-right: 10px;
  /* opacity: 0px; */
  margin-top: 3%;
}

.inputContainer .add {
  border: none;
  color: var(--background-white);
  background-color: var(--main-color);
}

.inputContainer table {
  text-align: center;
  margin-top: 10px;
  min-height: 100px;
}

.inputContainer thead {
  background-color: var(--background-table-head);
  margin-bottom: 20px;
}

.modal-content {
  direction: rtl;
}

/* checkboxContainer */
.checkboxContainer {
  display: block;
  position: relative;

  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.checkboxContainer input {
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 52px;
  background-color: rgba(103, 80, 164, 0.08);

  border-radius: 100px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer input ~ .checkmark {
  background: rgba(103, 80, 164, 0.08);
}

/* When the radio button is checked, add a green background   */
.checkboxContainer input:checked ~ .checkmark:after {
  left: 23px;
}

.checkboxContainer input ~ .checkmark {
  background: rgba(0, 0, 0, 0.25);
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: rgba(132, 203, 0, 1);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;

  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  /* background-image: url('../src/Images/close.png');
  background-repeat: no-repeat;
  background-position: center; */
}

/* Show the indicator (dot/circle) when checked */

/* Style the indicator (dot/circle) */
.checkboxContainer .checkmark:after {
}

/* ************************************************* */

/* Create a custom radio button */
.checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 52px;
  background-color: rgba(103, 80, 164, 0.08);

  border-radius: 100px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer input ~ .checkmark2 {
  background: rgba(103, 80, 164, 0.08);
}

/* When the radio button is checked, add a green background   */
.checkboxContainer input:checked ~ .checkmark2:after {
  left: 23px;
  background-image: url("../src/Images/check.png");
  background-repeat: no-repeat;
  background-position: center;
}

.checkboxContainer input ~ .checkmark2 {
  background-color: #797981;
  /* background:rgba(121, 121, 129, 1); */
  /* background: rgba(28, 27, 31, 0.08); */
}

.checkboxContainer input:checked ~ .checkmark2 {
  background-color: rgba(132, 203, 0, 1);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark2:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;

  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #d9d9d9;
  background-image: url("../src/Images/close.png");
  background-repeat: no-repeat;
  background-position: center;
}

/*******************************************************/

.checkboxlabel {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 29.98px;
  text-align: right;
  color: rgba(121, 121, 129, 1);
}

.full {
  width: 90%;
  height: 50px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
}

.h5Title {
  font-family: Cairo;
  font-size: 24px;
  font-weight: 600;
  line-height: 44.98px;
  text-align: right;
  color: var(--header-color);

}

.permissionsLayout {
  width: 100%;
  height: fit-content !important;
  top: 178px;
  left: 48px;
  padding: 16px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  /* gap: 25px; */
  border-radius: 8px;
  background-color: var(--background-white2) ;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.permissionsTableHeader {
  font-family: "Cairo";
  background-color: var(--main-color);
  color: var(--background-white);
  padding: 10px;
  /* width: 100%; */
  height: 50px;

  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  /* opacity: 0px; */
}

.permissionsTable {
  /* width: 1200px; */
  padding: 10px;
}

.stripe {
  margin-top: 10px;
}



.permissionsTable table thead {
  background-color: var(--input-bottom);
  color: var(--font-color);
}

.permissionsTable table {
  /* width: 1200px; */
  overflow: auto;
}

.permissionsTable td {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 26.24px;
}

.permissionsTable table thead th {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 26.24px;
  text-align: center;
}

.screensBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.permissionsTable .screensBox {
  width: 842px;
  height: 76px;
  gap: 8px;
  /* opacity: 0px; */
}

.screensBoxtitle {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  text-align: right;
}

.screenBoxPermission {
  /* width: 782px; */
  height: 38px;
  padding: 10px 16px 10px 16px;
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  /* gap: 74px; */
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
  /* gap: 10px; */
}

.screenBoxPermission label {
  color: rgba(51, 51, 51, 1);
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 29.98px;
  text-align: right;
}

.h5ModalTitle {
  font-family: Cairo;
  font-size: 24px;
  font-weight: 700;
  line-height: 44.98px;
  text-align: center;
  color: var(--main-color);
}

.container {
  display: block;
  position: relative;
  /* padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark3 {
  position: absolute;
  top: 6px;
  right: 0;
  height: 17px;
  width: 17px;
  background-color: rgba(235, 235, 235, 1);
}

.checkmark4 {
  background-color: var(--background-white);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark3 {
  background-color: rgba(254, 170, 0, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark3:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark3:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.permissions-save {
  /* width: 164px; */
  width: 15%;
  height: 50px;
  top: 839px;
  left: 58px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border: none;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
  background-color: var(--main-color);
  color: var(--background-white);
}

.marketingLayout {
  width: 1115px;
  height: 1026px;
  top: 190px;
  left: 40px;
  gap: 13px;
  border-radius: 8px 0px 0px 0px;
  /* opacity: 0px; */
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.activeButton {
  width: 130px;
  height: 40px;
  gap: 10px;
  border-radius: 8px;
  border: none;
  color: white;
  background-color: rgba(132, 203, 0, 1);
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  text-align: center;
  margin-top: 16px;
}

.blocked {
  background-color: rgba(240, 20, 47, 1);
}

.multislect_p {
  height: 45px;
  padding: 10px;
  /* gap: 280px; */
  border-radius: 4px;
  border: 1px solid rgba(0, 128, 251, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  text-align: center;
  width: 100%;
}

.multislect_option {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgba(254, 170, 0, 1);
  background-color: rgba(235, 235, 235, 1);
  padding: 10px;
}

.offersLayout {
  /* position: absolute; */
  /* width: 1105px; */
  width: 100%;
  /* height: 1169px; */
  top: 210px;
  /* left: 40px; */
  gap: 16px;
  margin-top: 37px;
  border-radius: 8px;
  background-color: var(--background-white2); 
 box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* overflow: auto; */

  /* background-color: aqua; */
}

.header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--main-color);
  color: var(--background-white);
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  border-radius: 8px;
}

.offersLayout .box1 {
  /* width: 1051px; */
  width: 100%;
  /* height: 546px; */
  padding: 16px;
  overflow: auto;
}
.font{
  color: var(--font-color);

}
.font-offers {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 29.98px;
  text-align: right;
}

.handelBtnsResponsive {
  width: 100%;
}

.customButton {
  width: 164px;
  height: 50px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save {
  background: var(--main-color);
}

.edit {
  background: rgba(254, 170, 0, 1);
}

.close {
  background: rgba(240, 20, 47, 1);
}

.setting_btn {
  width: 50%;
  height: 50px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  background: rgba(0, 128, 251, 1);
  color: white;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  border: none;
}

.add_bank_btn {
  /* width: 40%; */
  height: 50px;
  /* padding: 10px 16px 10px 16px; */
  border-radius: 4px 0px 0px 0px;
  border: 1px solid rgba(254, 170, 0, 1);
  color: rgba(254, 170, 0, 1);
  background: white;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
}

.add_bank_btn_active {
  color: white !important;
  background: rgba(254, 170, 0, 1) !important;
}

.settingLayout {
  padding: 10px 16px 10px 16px;
}

.orders_search_btn {
  /* width:  138px; */
  height: 50px;
  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  background: white;
  color: rgba(254, 170, 0, 1);
  border: 1px solid rgba(254, 170, 0, 1);
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 29.98px;
  /* text-align: right; */
}

.orders_search_btn_active {
  color: white;
  background: rgba(254, 170, 0, 1);
}

.circle {
  height: 100%;
  /* width: 30%; */
  border: none;
  border: 1px solid var(--main-color);
  background-color: var(--background-white);
  border-radius: 8px;
}

.green-filter-btn {
  background: rgba(132, 203, 0, 1);
  color: white;
  border: none;
  border: 1px solid var(--main-color, rgba(0, 128, 251, 1));
  height: 44px;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 29.98px;
  border: none;
}

/* .title input[type="date"]::-webkit-calendar-picker-indicator {
 
  filter: invert(0); 
  color: rgba(121, 121, 129, 1); 
} */

.checkmark_orders {
  height: 20px;
  width: 20px;
  top: 0px;
  border: 1px solid rgb(0, 128, 251);
  background: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark_orders:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark_orders:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark_orders:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1px solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.primary-btn {
  background: rgba(0, 128, 251, 1);
}

.yellow-btn {
  background: rgb(254, 170, 0);
}

.H4-Regular {
  font-family: Cairo;
  font-size: 32px;
  font-weight: 400;
  line-height: 59.97px;
  display: flex;
  justify-content: center;
  width: 300px;
  margin: auto;
  margin-top: -94px;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 35px;
  /* text-align: center;
position: absolute;
transform: translate(-32%, -85px); */
}

.city-container {
  background-image: url("../src/Images/medical-system.png");
  background-size: cover;
  height: 350px;
}

.h4-header {
  color: rgba(0, 128, 251, 1);
  font-family: Cairo;
  font-size: 32px;
  font-weight: 600;
  line-height: 51.53px;
  /* text-align: right; */
}

.p-text {
  font-family: Cairo;
  font-size: 20px;
  font-weight: 600;
  line-height: 37.48px;
  display: flex;
  color: var(--header-color);
  /* overflow: hidden; */
}

.inputContainer2 {
  display: flex;
  /* width: auto; */
  gap: 8px;
}

.inputContainer2 input {
  border: none;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--input-bottom);
  border-radius: 0;
}

.sub_heading {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  cursor: pointer;
  width: fit-content;
  /* text-align: right; */
}

.verify th {
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 128, 251, 1);
  padding: 20px;
}

.verify table {
  border: 1px solid rgba(121, 121, 129, 1);
}

.verify thead {
  border-bottom: 1px solid rgba(121, 121, 129, 1);
}
.verify td {
  text-align: center;
  font-size: 14px;
  padding: 10px;
}

.member-details-title {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  text-align: right;
}

.hr_line {
  margin: 7px;
  margin-right: 2%;
  margin-left: 2%;
  border-top: 3px solid;
}

.filter-deposite-btn {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  line-height: 26.24px;
  /* text-align: right; */
  background: var(--main-color, rgba(0, 128, 251, 1));
  width: 120px;
  height: 38.59px;
  border-radius: 4px;
  color: white;
  border: none;
}

.cancel {
  color: rgba(0, 128, 251, 1);
  background: white;
  border: 1px solid rgba(0, 128, 251, 1);
}

.desposites-box {
  height: 35px;
  margin: auto;
  min-width: 40px;
  border: 1px solid rgba(0, 128, 251, 1);
  text-align: center;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
  padding: 0 10px 0 10px;
}

.add_desposite_btn {
  /* width: 143px; */
  height: 50px;
  /* padding: 10px 16px 10px 16px; */
  border-radius: 4px 0px 0px 0px;
  border: 1px solid rgba(254, 170, 0, 1);
  color: rgba(254, 170, 0, 1);
  background: white;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
}

.dropdown-menu {
  border: 1px solid rgba(0, 128, 251, 1) !important;
}

.deposite-accepted-btn {
  border-radius: 8px;
  border: 1px solid rgba(132, 203, 0, 1);
  color: rgba(132, 203, 0, 1);
}

.deposite-accepted-pending {
  border-radius: 8px;
  border: 1px solid rgba(240, 20, 47, 1);
  color: rgba(240, 20, 47, 1);
}

.deposite-accepted-refused {
  border-radius: 8px;
  border: 1px solid rgba(0, 128, 251, 1);
  color: rgba(0, 128, 251, 1);
}

.deposite-accepted-archeived {
  border-radius: 8px;
  border: 1px solid rgba(254, 170, 0, 1);
  color: rgba(254, 170, 0, 1);
}

.add-deposite-date {
  border: 1px solid rgba(132, 203, 0, 1);
  width: 333px;
  margin-top: 22px;
  /* height: 50px; */
}

.liquidation-details-p {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 700;
  line-height: 29.98px;
}

.liquidation-details {
  font-family: Cairo;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}

.blue {
  color: rgba(0, 128, 251, 1);
}

.green {
  color: rgba(132, 203, 0, 1);
}

.yellow {
  color: rgba(254, 170, 0, 1);
}

.pink {
  color: rgba(249, 32, 122, 1);
}

.pinkButton {
  color: white;
  background-color: rgba(249, 32, 122, 1);
  font-family: Cairo;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  width: 164px;
  height: 50px;
  margin-top: 4px;
  border-radius: 4px;
}

.liquidation-details-textarea {
  /* width: 94.5%; */
  border: 1px solid rgba(121, 121, 129, 1);
  border-radius: 8px;
  min-height: 198px;
}

textarea:focus {
  border: 3px solid rgba(121, 121, 129, 1);
}

.citylabel {
  font-size: 22px;
}

.inputResponiveWidth {
  width: 42%;
}

.depositesSearchResponsive {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.pinkReceiptNumber {
  background-color: orange;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  width: 70px;
  margin: auto;
  color: #000;
}



.verifiedTabel {
  margin: -9px;
}

.verifiedTabel thead {
  background: #e0e0e0;
}
.usersSearchBox {
  padding: 5px;
}

.customSuberVisierBox {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 8px 0;
  width: 100%;
  text-align: center;
  font-weight: 700;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.customSuberVisierBox:hover {
  border-color: #0078d4; /* Change border color on hover */
  background-color: #f0f8ff; /* Light background on hover */
}

@media (max-width: 1320px) and (min-width: 1200px) {
  /* .usersSearchBox, */
  .searchBar {
    max-width: 900px;
  }
}
@media (max-width: 1200px) and (min-width: 1000px) {
  /* .usersSearchBox, */
  .searchBar {
    max-width: 720px;
  }
  .font {
    font-size: 15px;
    font-weight: 600;
  }
}
@media (max-width: 1000px) and (min-width: 900px) {
  /* .usersSearchBox, */
  .searchBar {
    max-width: 650px;
  }
  .font {
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 900px) and (min-width: 800px) {
  /* .usersSearchBox, */
  .searchBar {
    max-width: 550px;
  }
  .customButton {
    width: 120px;
  }

  .responsiveBankName {
    width: 240px;
  }
  .font {
    font-size: 14px;
    font-weight: 600;
  }

  .startSearch,
  .addButton,
  .activeSearch,
  .dateResponsive,
  .cancel,
  .filter-deposite-btn {
    font-size: 12px;
    width: 90px;
  }
}

@media (max-width: 800px) and (min-width: 650px) {
  .searchBar {
    max-width: 430px;
  }
}

@media (max-width: 1800px) and (min-width: 800px) {
  .sidebarMenu {
    width: 210px;
  }

  .dashboard {
    padding: 0 !important;
  }

  .h4-header {
    font-size: 32px;
  }


  .responsiveBankName {
    width: 270px;
  }
}

@media (max-width: 800px) and (min-width: 550px) {
  .dateResponsive,
  .cancel,
  .startSearch,
  .addButton,
  .activeSearch,
  .filter-deposite-btn {
    font-size: 12px;
  }

  .sidebarMenu {
    width: 180px;
  }
  .font,
  .addButton {
    font-size: 14px;
    font-weight: 500;
  }

  .link_text {
    font-size: 13px;
    line-height: 15px;
    height: 20px;
    
  }

  .h4-header {
    font-size: 25px;
  }

  .p-text {
    font-size: 15px;
  }

  .title {
    font-size: 10px;
    font-weight: 700;
  }

  .permissionsTable td {
    font-size: 12px;
    font-weight: 500;
  }

  .searchBar {
    font-size: 14px;
    font-weight: 500;
  }

  .permissionsTable table thead th {
    font-size: 12px;
    font-weight: 500;
  }

  .pdfBox,
  .filter {
    font-size: 12px;
  }

  .checkmark2 {
    height: 27px;
    width: 47px;
    border-radius: 100px;
  }

  .checkmark2:after {
    width: 20px;
    height: 20px;
  }

  .screenBoxPermission {
    display: contents;
  }

  .checkmark3 {
    width: 14px;
    height: 14px;
  }

  .responsiveFont,
  .add_bank_btn {
    font-size: 12px;
  }
  .orders_search_btn,
  .green-filter-btn,
  .permissionsTableHeader,
  input::placeholder,
  .desposites-box,
  .add_desposite_btn,
  .customButton,
  .font-offers,
  .inputResponiveWidth,
  .sub_heading,
  .responsiveFont,
  .username,
  .citylabel,
  .screensBoxtitle,
  .h5Title,
  .header,
  .screenBoxPermission label {
    font-size: 14px;
  }

  .label {
    font-size: 12px;
  }

  .add_bank_btn {
    height: 40px;
    font-size: 12px;
  }

  .customButton {
    width: 80px;
  }

  .tabelfullsize {
    width: 800px;
  }
}

@media (max-width: 650px) and (min-width: 550px) {
  /* .usersSearchBox, */
  .searchBar {
    max-width: 335px;
  }

  .inputResponiveWidth,
  .label {
    font-size: 12px;
  }

  .responsiveFont,
  .add_bank_btn {
    font-size: 10px;
  }

  .responsiveMobileBankBtn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 550px) and (min-width: 0) {
  .verify{
    width: 100%;
  }
  .betweenAndCenterResponsive {
    justify-content: center;
  }
  .colouminmobile {
    flex-direction: column;
  }
  .width100inmobile {
    width: 100%;
  }

  .width45 {
    width: 100%;
  }
  .pagination a {
    width: 14px;
    font-size: 12px;
    /* padding: 10px; */
    /* margin: 10px; */
  }
  .pagee-item a {
    width: 45px;
    border-radius: 3px;
  }

  /* .usersSearchBox, */
  .searchBar {
    max-width: 270px;
  }

  .tabelfullsize {
    width: 550px;
  }

  .eyeMobileWidth {
    width: 15px;
  }

  .seetingpProfileMobileResponsive {
    flex-direction: column;
    width: 330%;
  }

  .dateResponsive,
  .cancel,
  .startSearch,
  .addButton,
  .activeSearch,
  .filter-deposite-btn {
    font-size: 10px;
  }

  /* .sidebarMenu {
    width: 90px;
  } */

  .link_text {
    font-family: Cairo;
    font-size: 9px;
    font-weight: 400;
    line-height: 20px;
  }

  .h4-header {
    font-size: 20px;
  }

  .p-text {
    font-size: 11px;
  }

  .title {
    font-size: 8px;
    font-weight: 700;
  }

  .permissionsTable td {
    font-size: 9px;
    font-weight: 400;
  }

  .permissionsTable table thead th {
    font-size: 9px;
    font-weight: 400;
  }

  .pdfBox,
  .filter {
    font-size: 14px;
  }

  .checkmark2 {
    height: 17px;
    width: 38px;
    border-radius: 100px;
  }

  .checkmark2:after {
    width: 10px;
    height: 10px;
  }

  .screenBoxPermission {
    display: contents;
  }
  .font,
  .addButton {
    font-size: 12px;
    font-weight: 500;
  }

  .checkmark3 {
    width: 12px;
    height: 12px;
  }

  .responsiveFont,
  .add_bank_btn {
    font-size: 10px;
  }

  .searchBar {
    font-size: 14px;
    font-weight: 500;
  }
  .verify th,
  .verify td,
  .orders_search_btn,
  .green-filter-btn,
  .permissionsTableHeader,
  input::placeholder,
  .desposites-box,
  .customButton,
  .font-offers,
  .sub_heading,
  .responsiveFont,
  .username,
  .citylabel,
  .screensBoxtitle,
  .h5Title,
  .header,
  .screenBoxPermission label {
    font-size: 12px;
  }

  .customButton {
    width: 50px;
  }

  .add_desposite_btn,
  .inputResponiveWidth,
  .label {
    font-size: 10px;
  }

  .add_bank_btn {
    height: 33px;
    font-size: 10px;
  }

  .responsiveMobileBankBtn {
    display: flex;
    flex-direction: column;
  }

  .dateResponsive,
  .cancel,
  .filter-deposite-btn {
    font-size: 12px;
    width: 90px;
  }
  .inputContainer2 {
    flex-direction: column;
  }
  .inputResponiveWidth {
    width: 100%;
  }
}
/* .multislect_p {
  max-width: 250px;
} */

@media (max-width: 700px) and (min-width: 0) {
  .depositesSearchResponsive {
    display: flex;
    flex-direction: column;
  }

  .dateResponsive,
  .cancel,
  .filter-deposite-btn {
    font-size: 12px;
  }
}

.sideBarBurger {
  width: 40px;
}
.inputsReduisAndPadding {
  border-radius: 4px;
  padding: 10px 16px;
  height: 38px;
}
.inputsReduisAndPadding2 {
  border-radius: 4px;
  padding: 10px 16px;
  height: 45px;
}
.inputsReduisAndPadding2WithMinHight {
  height: auto;
  border-radius: 4px;
  padding: 10px 16px;
  min-height: 45px;
}
.membersBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 153px;
  border-radius: 4px;
}
.membersBtnsWithHeight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 153px;
  height: 45px;
  border-radius: 4px;
}
.membersBtnsWithHeight2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 4px;
}
.membersBtns2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  border-radius: 4px;
}
.flexRow {
  display: flex;
}
.minWidthMultiSelector {
  min-width: 600px;
  border-radius: 4px;
  padding: 10px 16px;
  height: 45px;
}

.dropdown-list {
  position: relative;
  top: 50%;
  width: 70%;
  margin: auto;
  border: 1px solid #ddd;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}



.custom-select {
  position: relative;
  padding: 10px;
  border: 1px solid rgba(0, 128, 251, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: var(--background-white2);
  overflow: visible;  /* Ensure the dropdown can be fully displayed */

}

.selected-option {
  flex-grow: 1;
  user-select: none;
  display: flex;
  justify-content: center;
}

.arrow {
  transition: transform 0.2s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown2 {
  position: absolute;
  top: 100%; /* Ensures the dropdown opens at the bottom */
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--background-white2);
  border: 1px solid #ccc;
  z-index: 99999999;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-option2{
  padding: 10px;
  cursor: pointer;
  background-color: var(--background-white2);
  display: flex;
  justify-content: center;
}

.dropdown-option2:hover,
.dropdown-option2.selected {
  background-color: var(--input-bottom);
}
.customModal .modal-dialog {
  max-width: 1000px;
  width: 1000px; /* Ensures the modal's width */

}

.modal-dialog{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.customModal .modal-body {
  width: 1000px; /* Ensures the body matches the modal's width */
  margin: 0 auto; /* Centers the content inside the modal */
}
.modal-content{
  width: 1000px !important;
  margin: auto !important;
  
}


/* Spinner.css */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: semi-transparent background */
  z-index: 9999; /* Ensures the spinner is on top of all other content */
}
