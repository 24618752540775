.DeliveredCardGraphComponentContainer {
    display: flex;
    align-items: center;
  }
  
  .chartContainer {
    position: relative;
    width: 150px;
    height: 50px;
  }
  
  .infoContainer {
    margin-left: 10px;
    text-align: right;
  }
  
  .infoContainer h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .infoContainer p {
    margin: 0;
    font: 700 12px Cairo;
    color: #888;
  }
  
  .infoContainer .percentage {
    color: #28a745; 
  }
  .DeliveredCardGraphComponentMasterContainer{
    background-color: var(--black-white);
    width: 30%;
    min-width: 280px;
    padding: 14px;
    border-radius: 10px;
    border : 2px solid #0000003a;
    height: 140px;
}
  