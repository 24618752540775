@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap");


.CreateBoxComponentPerantMaster {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.CreateBoxComponentPerant {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 45%;
  /* gap: 40px; */
}

.CreateBoxComponent1 {
  display: flex;
  flex-direction: column;
  direction: rtl;
  gap: 20px;
  width: 100%;
}
.CreateBoxComponent1Label {
  display: flex;
  justify-content: right;
  font: 600 16px Cairo;
  color: #333333;
  line-height: 29.98px;
  text-align: right;
}

.CreateBoxComponentInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 8px;
  font: 600 16px Cairo;
  line-height: 29.98px;
  text-align: center;
}
.datewidth{
  width: 45%;
}

.CreateBoxComponentStartEndDate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #feaa00;
  font: 600 16px Cairo;
  line-height: 29.98px;
  text-align: center;
}
.CreateBoxComponentDate {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}
.CreateBoxComponentShow {
  display: flex;
  justify-content: right;
  align-items: center;
  min-width: 220px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid  #797981;
  font: 600 16px Cairo;
  color: #797981;
  line-height: 29.98px;
  text-align: right;
  margin-top: 30px;
  padding: 10px;
  margin-right: 24px;
}


.handelToggel{
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 150px;

}
.toggel{
  margin-top: 34px;
}
/* @media (max-width:1300px) and (min-width:1000px) {
  .CreateBoxComponentStartEndDate,
  .CreateBoxComponentInput{
    width: 350px;
  }
}
@media (max-width:1000px) and (min-width:800px) {
  .CreateBoxComponentStartEndDate,
  .CreateBoxComponentInput{

    width: 250px;
  }
  
} */
@media (max-width:800px) and (min-width:550px) {
  /* .CreateBoxComponentStartEndDate,
  .CreateBoxComponentInput{
    width: 180px;
  } */
  .CreateBoxComponentShow,
  .CreateBoxComponentShow,
  .CreateBoxComponent1Label{
    font-size: 12px;
  }
  .handelToggel{
    gap: 50px;
  }
}
@media (max-width:550px) and (min-width:0) {
  /* .CreateBoxComponentStartEndDate,
  .CreateBoxComponentInput{
    width: 200px;
  } */
  .CreateBoxComponentShow,
  .CreateBoxComponent1Label{
    font-size: 10px;
  }
  .handelToggel{
    gap: 30px;
  }
  .CreateBoxComponentDate,
  .CreateBoxComponentPerantMaster{
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .datewidth,
  .CreateBoxComponentPerant{
    width: 100%;
  }
}
