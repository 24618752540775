.HistogramChartMasterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 650px;
  background-color: var(--black-white);
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  border: 2px solid #0000003a;
}
.HistogramChartContainer {
  width: 95%;
  min-width: 350px;
  height: 580px;
  min-height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.HistogramChartTitle{
  font: 700 20px Cairo;
  margin-top: 30px;
  color: var(--input-color);
}
